const DocMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      }
    ],
  },
  {
    heading: "Client Management",
    route: "/company",
    pages: [
      {
        sectionTitle: "Client Management",
        route: "/company",
        svgIcon: "/media/icons/duotune/finance/fin006.svg",
        fontIcon: "bi-printer",
        sub: [
          {
            heading: "Companies",
            route: "/company",
          },
          {
            heading: "KMP's",
            route: "/company/kmps",
          },
          {
            heading: "Contact Person",
            route: "/company/contacts",
          }                          
        ],
      },
      {
        sectionTitle: "Product Management",
        route: "/product",
        svgIcon: "/media/icons/duotune/finance/fin006.svg",
        fontIcon: "bi-printer",
        sub: [
           {
             heading: "Categories",
             route: "/products/categories",
            },
            {
              heading: "Products",
              route: "/products",            
             },
             {
              heading: "Product Standard",
              route: "/products/product-standard",            
             },
             {
              heading: "Product Grade",
              route: "/products/product-grade",            
             },
             {
              heading: "Product Hsn",
              route: "/products/product-hsn",            
             },
             {
              heading: "Product Attribute",
              route: "/products/product-attribute",            
             },
             {
              heading: "Product Brand",
              route: "/products/product-brand",
             },
            //  {
            //   heading: "Product Prices",
            //   route: "/products/product-prices",
            //  },
        ],
      },    
      // {
      //   sectionTitle: "Manage KMP",
      //   route: "/company/kmps",
      //   svgIcon: "/media/icons/duotune/communication/com014.svg",        
      //   fontIcon: "bi-printer",
      //   sub: [
      //     {
      //       heading: "KMP's",
      //       route: "/company/kmps",
      //     }                    
      //   ],
      // },
      // {
      //   sectionTitle: "CRM",
      //   route: "/crm",
      //   svgIcon: "/media/icons/duotune/finance/fin006.svg",
      //   fontIcon: "bi-printer",
      //   sub: [
      //     {
      //       heading: "Contacts",
      //       route: "/crm/contacts",
      //     }                    
      //   ],
      // },
      {
        sectionTitle: "Research",
        route: "/research",
        svgIcon: "/media/icons/duotune/communication/com014.svg",        
        fontIcon: "bi-printer",
        sub: [
          {
            heading: "News",
            route: "/research/news",
          },
          {
            heading: "Reports",
            route: "/research/reports",
          },
          {
            heading: "Price Index",
            route: "/research/price-index",
          },
        ],
      },
    ],
  },  
  // {
  //   pages: [
  //     {
  //       heading: "Product Management",
  //       route: "/under-construction",
  //       svgIcon: "/media/icons/duotune/finance/fin003.svg",
  //       fontIcon: "bi-printer",
  //     }
  //   ],
  // },
  // {
  //   pages: [
  //     {
  //       heading: "Admin",
  //       route: "/under-construction",
  //       svgIcon: "/media/icons/duotune/technology/teh004.svg",
  //       fontIcon: "bi-sticky",
  //     }
  //   ],
  // },
  // {
  //   heading: "Services",
  //   route: "/apps",
  //   pages: [
  //     {
  //       sectionTitle: "customers",
  //       route: "/customers",
  //       svgIcon: "/media/icons/duotune/finance/fin006.svg",
  //       fontIcon: "bi-printer",
  //       sub: [
  //         {
  //           heading: "gettingStarted",
  //           route: "/apps/customers/getting-started",
  //         },
  //         {
  //           heading: "customersListing",
  //           route: "/apps/customers/customers-listing",
  //         },
  //         {
  //           heading: "customerDetails",
  //           route: "/apps/customers/customer-details",
  //         },
  //       ],
  //     },
  //     {
  //       sectionTitle: "authentication",
  //       svgIcon: "/media/icons/duotune/technology/teh004.svg",
  //       fontIcon: "bi-sticky",
  //       sub: [
  //         {
  //           sectionTitle: "basicFlow",
  //           sub: [
  //             {
  //               heading: "signIn",
  //               route: "/sign-in",
  //             },
  //             {
  //               heading: "signUp",
  //               route: "/sign-up",
  //             },
  //             {
  //               heading: "passwordReset",
  //               route: "/password-reset",
  //             },
  //           ],
  //         },
  //         {
  //           heading: "error404",
  //           route: "/404",
  //         },
  //         {
  //           heading: "error500",
  //           route: "/500",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default DocMenuConfig;
